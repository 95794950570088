const sendToMixpanel = (event, properties, action = null) => {
  switch (event) {
    case 'track':
      window.mixpanel.track(action, properties)
      break
    case 'reset':
      window.mixpanel.reset()
      localStorage.removeItem('mixpanelId')
      break
    case 'identify':
      window.mixpanel.identify(action)
      localStorage.setItem('mixpanelId', action)
      break
    default:
      return
  }
}

const configureAnalytics = (event, properties, action, company) => {
  switch (company) {
    case 'mixpanel':
      sendToMixpanel(event, properties, action)
      break
    default:
      return
  }
}

const commonProperties = {
  CLIENT_ID: 'cc6b6644-7057-430c-acb8-1fb81cf7bcbb',
  CLIENT_NAME: 'Circles Singapore',
}

export const sendAnalytics = (event, properties, action, eventToCompany) => {
  const allProperties = { ...properties, ...commonProperties }
  eventToCompany.forEach((company) => {
    configureAnalytics(event, allProperties, action, company)
  })
}
