import React, { createElement, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import Footer from '../../component/Footer/Footer'
import Banner from '../../component/Banner/Banner'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch, useSelector } from 'react-redux/es'
import { getGames, useGetGamesMutation } from '../../../store/games/actions'
import { LoggedInBanner } from '../../component/LoggedInBanner/LoggedInBanner'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { setGames } from '../../../store/games/allGameSlice'
import { setAllCategory } from '../../../store/ViewAll/categorySlice'
import { SkeletonCards } from '../../component/Common/SkeletonCards'
import {
  useUserContinuePlayMutation,
  useUserHistoryMutation,
  useUserNameMutation,
  useUserNumberMutation,
  useUserRecordHistoryMutation,
  useUserReelRecomendationMutation,
  useUserSavedGamesMutation,
  userRecordHistorySlice,
  userReelRecomendationSlice,
  useUserAllCategoryMutation,
  useUserAllCategoryIdMutation,
} from '../../../store/user/actions'
import { useVerifyOTPMutation } from '../../../store/login/verifyUser/actions'
import { setCredentails } from '../../../store/login/loginSlice'
import { sendAnalytics } from '../../../utils/mixpannelConn'
import toast from 'react-hot-toast'

import { Error } from '../../component/icones'
import { Helmet } from 'react-helmet'
import { useScroll } from '../../../providers/scrollProvider'
import { CheckAppInstalled } from '../../component/Common/CheckAppInstalled'
import ContinuePlay from '../../component/Common/ContinuePlay'
import ReelRecomendation from '../../component/Common/ReelRecomendation'
import { setContinuePlay } from '../../../store/ContinuePlay/continuePlaySlice'
import { setSavedGames } from '../../../store/SavedGames/savedSlice'
import SavedGames from '../../component/Common/SavedGames'
import { useMediaQuery } from '../../../hooks/useMediaQuery'
import { scrollElement } from '../../../utils/scrollElement'
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver'
import { setAllCategoryId } from '../../../store/categoryId/categoryIdSlice'
import { useOnScreen } from '../../../hooks/useOnScreen'
import { CategoryComponent } from '../../component/Home/CategoryComponent'

const Home = () => {
  const dispatch = useDispatch()
  const games = useSelector((state) => state.games)
  const loginState = useSelector((state) => state.login)
  const scrollState = useSelector((state) => state.scroll)
  const continuePlay = useSelector((state) => state?.continuePlay)
  const savedR = useSelector((state) => state.savedR)
  const allCategory = useSelector((state) => state.allCategory)
  const allCategoryId = useSelector((state) => state.allCategoryId)
  const [accessToken, setAccessToken] = useLocalStorage('accessToken', null)

  const location = useLocation()

  const [relatedApps, setRelatedApps] = useState([])

  const [refreshToken, setRefreshToken] = useLocalStorage('refreshToken', null)

  const [reelRecomendation, setReelRecomendation] = useState([])

  const [userAllCategoryId] = useUserAllCategoryIdMutation()

  const [verifyOTP, { isLoading: isVerifyOtpLoading }] = useVerifyOTPMutation()
  const [userSavedGames, { isLoading: isSavedGamesLoading }] =
    useUserSavedGamesMutation()
  const [userHistory, { isLoading: isHistoryLoading }] =
    useUserHistoryMutation()
  const [userRecordHistory] = useUserRecordHistoryMutation()
  const [userContinuePlay, { isLoading: isContinuePlayLoading }] =
    useUserContinuePlayMutation()
  const [userAllCategory, { isLoading: isAllCategoryLoading }] =
    useUserAllCategoryMutation()
  const [userReelRecomendation, { isLoading: isReelRecomendationLoading }] =
    useUserReelRecomendationMutation()
  const [userName] = useUserNameMutation()
  const [userReport, setUserReport] = useState(undefined)
  const [isAppInstalled, setIsAppInstalled] = useState(false)
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false)
  const [getGames, { isLoading: isAllGamesLoading }] = useGetGamesMutation()
  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const {
    scrollHomepageForNotLoggedInUser,
    setScrollHomepageForNotLoggedInUser,
    scrollHomePageForLoggedInUser,
    setScrollHomePageForLoggedInUser,
  } = useScroll()

  const [recomm, setRecom] = useState([])

  const [page, setPage] = useState(1)
  const [isIOSorSafari, setIsIOSorSafari] = useState(false)
  const [continuePlayPage, setContinuePlayPage] = useState(1)

  const [savedGamePage, setSavedGamesPage] = useState(1)

  const scrollContainerRef = useRef(null)

  const scrollSavedRef = useRef(null)

  const continueCarouselRef = useRef(null)
  const savedCarouselRef = useRef(null)

  const [prevScrollPosition, setPrevScrollPosition] = useState(0)
  const [prevSavedScrollSPosition, setPrevSavedScrollSPosition] = useState(0)

  const [showLeftIcon, setShowLeftIcon] = useState(false)
  const [showRightIcon, setShowRightIcon] = useState(true)
  const [isGameVisible, setIsGameVisible] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const showCarouselICon = continuePlay?.data?.length > 6

  const handleMouseEnter = () => {
    setIsHovered(true)
  }
  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const [isSavedGameVisible, setIsSavedGameVisible] = useState(false)

  const getAllCategoryId = async () => {
    try {
      const res = await userAllCategoryId().unwrap()
      const allCategories = res?.categories?.reduce(
        (acc, val) => (acc ? [...acc, val?.uuid] : [val?.uuid]),
        []
      )
      return allCategories
    } catch (error) {
      console.log(error)
    }
  }

  const fetchSingleCategory = async (categoryId, page) => {
    try {
      const res = await userAllCategory({
        categoryId,
        page,
      }).unwrap()
      return res
    } catch (error) {
      console.error(error)
    }
  }

  const fetchCategories = async (categories) => {
    for (const categoryId of categories) {
      try {
        const res = await fetchSingleCategory(categoryId, 1)
        if (res?.results?.games?.length > 0) {
          dispatch(setAllCategory(res))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (
      location?.search?.split('=')[0]?.substring(1) === 'userId' &&
      location?.search?.split('=')[1]
    ) {
      const id = location?.search?.split('=')[1]
      sendAnalytics('identify', {}, id, ['mixpanel'])
    }
  }, [location])

  const handleAllGamesClicked = async (game_name, uid, updatedAt) => {
    const properties = {
      GAME_NAME: game_name,
      GAME_FROM: 'Home',
      GAME_UUID: uid,
      SEGMENT_NAME: 'All Games',
    }
    sendAnalytics('track', properties, 'Play Game', ['mixpanel'])
  }

  useEffect(() => {
    getAllCategoryId().then((res) => {
      if (res && allCategory?.data?.length === 0) {
        fetchCategories(res)
      }
    })
  }, [])

  const handleScrollForNotLoggedInUser = () =>
    setScrollHomepageForNotLoggedInUser(window.scrollY)
  const handleScrollForLoggedInUser = () =>
    setScrollHomePageForLoggedInUser(window.scrollY)

  useEffect(() => {
    if (!loginState.accessToken) {
      window.scrollTo(0, scrollHomepageForNotLoggedInUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllGamesLoading,
    isSavedGamesLoading,
    isContinuePlayLoading,
    isReelRecomendationLoading,
    games,
    userReport,
    loginState,
  ])

  useEffect(() => {
    if (
      !isAllGamesLoading &&
      !games?.isLoading &&
      games?.data?.results?.length > 0 &&
      !loginState.accessToken &&
      !userReport
    ) {
      document.addEventListener('scroll', handleScrollForNotLoggedInUser)
    }
    return () =>
      document.removeEventListener('scroll', handleScrollForNotLoggedInUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAllGamesLoading,
    isSavedGamesLoading,
    isContinuePlayLoading,
    isReelRecomendationLoading,
    games,
    loginState,
    userReport,
  ])

  useEffect(() => {
    if (
      !isAllGamesLoading &&
      !games?.isLoading &&
      games?.data?.results?.length > 0 &&
      !!loginState.accessToken &&
      userReport &&
      mobile &&
      name
    ) {
      document.addEventListener('scroll', handleScrollForLoggedInUser)
    }
    return () =>
      document.removeEventListener('scroll', handleScrollForLoggedInUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllGamesLoading, games, loginState, userReport])

  const arePageNosLessThanTwo = continuePlayPage < 2
  const areSavedGamePageNosLessThanTwo = savedGamePage < 2
  const areAllComponentsLoading =
    (isAllGamesLoading ||
      isSavedGamesLoading ||
      isContinuePlayLoading ||
      isReelRecomendationLoading) &&
    arePageNosLessThanTwo &&
    areSavedGamePageNosLessThanTwo

  return (
    <>
      <div id="gamecon" className="max-w-[960px] mx-auto sm:px-6 lg:px-8">
        {areAllComponentsLoading ? (
          <>
            <div className="my-8">
              <SkeletonCards />
            </div>
          </>
        ) : (
          <>
            <Helmet>
              <title>Gaming - Circles.Life</title>
              <meta
                name="description"
                content="Discover a vast collection of free games, from action to strategy games and enjoy the convenience of playing multiple games in one place, without the need for downloads. Experience endless hours of online gaming fun, all for free."
              />
            </Helmet>
            <div className="py-4">
              {allCategory?.data?.map((data) =>
                createElement(CategoryComponent, {
                  key: data?.results?.uuid,
                  games: data?.results,
                  categoryId: data?.results?.uuid,
                  totalpages: allCategory?.totalpages,
                  fetchSingleCategory: fetchSingleCategory,
                  handleAllGamesClicked: handleAllGamesClicked,
                })
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}
export default Home
