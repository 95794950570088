import { apiSlice } from '../api/apiSlice'

export const gamesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGames: builder.mutation({
      query: (credentials) => ({
        url: '/game/category?game_size=-1&page_size=-1&platform=WEB&language=en',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetGamesMutation } = gamesSlice
